import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MultiSolicitationService {

  constructor() { }

  dados1WA(inputs, forma_entrega, valorServico, frete) {
    let date1
    if (inputs.dados1.data_ato) {
      date1 = new Date(Number(inputs.dados1.data_ato.substr(0, 4)), Number(inputs.dados1.data_ato.substr(5, 2) - 1), Number(inputs.dados1.data_ato.substr(8, 2)))
    }
    return {
      canal: 2,
      dados: [
        {
          data_ato: date1 || null,
          nome_partes: inputs.dados1.nome_partes,
          tipo_ato: inputs.dados1.tipo_ato,
          livro: inputs.dados1.livro_ato,
          folha: inputs.dados1.folha_ato,
        }
      ],
      forma_entrega: forma_entrega,
      endereco: {
        cep: inputs.cep,
        logradouro: inputs.logradouro,
        numero: inputs.numero,
        complemento: inputs.complemento,
        bairro: inputs.bairro,
        cidade: inputs.cidade,
        uf: inputs.uf
      },
      dados_solicitante: {
        nome: inputs.nome.trim(),
        cpf_cnpj: inputs.cpf_cnpj.replace(/\D/g, ''),
        email: inputs.email,
        telefone: inputs.telefone.replace(/\D/g, '')
      },
      mensagem: inputs.mensagem,
      valor_solicitacao: Number(valorServico.toFixed(2)),
      valor_frete: Number(frete.toFixed(2))
    }

  }

  dados1WOA(inputs, forma_entrega, valorServico, frete) {
    let date1
    if (inputs.dados1.data_ato) {
      date1 = new Date(Number(inputs.dados1.data_ato.substr(0, 4)), Number(inputs.dados1.data_ato.substr(5, 2) - 1), Number(inputs.dados1.data_ato.substr(8, 2)))
    }
    return {
      canal: 2,
      dados: [
        {
          data_ato: date1 || null,
          nome_partes: inputs.dados1.nome_partes,
          tipo_ato: inputs.dados1.tipo_ato,
          livro: inputs.dados1.livro_ato,
          folha: inputs.dados1.folha_ato,
        }
      ],
      forma_entrega: forma_entrega,
      endereco: {
        cep: inputs.cep,
        logradouro: inputs.logradouro,
        numero: inputs.numero,
        complemento: inputs.complemento,
        bairro: inputs.bairro,
        cidade: inputs.cidade,
        uf: inputs.uf
      },
      dados_solicitante: {
        nome: inputs.nome.trim(),
        cpf_cnpj: inputs.cpf_cnpj.replace(/\D/g, ''),
        email: inputs.email,
        telefone: inputs.telefone.replace(/\D/g, '')
      },
      mensagem: inputs.mensagem,
      valor_solicitacao: Number(valorServico.toFixed(2)),
      valor_frete: Number(frete.toFixed(2))
    }
  }

  dados2WA(inputs, forma_entrega, valorServico, frete) {
    let date1
    let date2
    if (inputs.dados1.data_ato) {
      date1 = new Date(Number(inputs.dados1.data_ato.substr(0, 4)), Number(inputs.dados1.data_ato.substr(5, 2) - 1), Number(inputs.dados1.data_ato.substr(8, 2)))
    }
    if (inputs.dados2.data_ato) {
      date2 = new Date(Number(inputs.dados2.data_ato.substr(0, 4)), Number(inputs.dados2.data_ato.substr(5, 2) - 1), Number(inputs.dados2.data_ato.substr(8, 2)))
    }

    return {
      canal: 2,
      dados: [
        {
          data_ato: date1 || null,
          nome_partes: inputs.dados1.nome_partes,
          tipo_ato: inputs.dados1.tipo_ato,
          livro: inputs.dados1.livro_ato,
          folha: inputs.dados1.folha_ato,
        },
        {
          data_ato: date2 || null,
          nome_partes: inputs.dados2.nome_partes,
          tipo_ato: inputs.dados2.tipo_ato,
          livro: inputs.dados2.livro_ato,
          folha: inputs.dados2.folha_ato,
        }
      ],
      forma_entrega: forma_entrega,
      endereco: {
        cep: inputs.cep,
        logradouro: inputs.logradouro,
        numero: inputs.numero,
        complemento: inputs.complemento,
        bairro: inputs.bairro,
        cidade: inputs.cidade,
        uf: inputs.uf
      },
      dados_solicitante: {
        nome: inputs.nome.trim(),
        cpf_cnpj: inputs.cpf_cnpj.replace(/\D/g, ''),
        email: inputs.email,
        telefone: inputs.telefone.replace(/\D/g, '')
      },
      mensagem: inputs.mensagem,
      valor_solicitacao: Number(valorServico.toFixed(2)),
      valor_frete: Number(frete.toFixed(2))
    }

  }

  dados2WOA(inputs, forma_entrega, valorServico, frete) {
    let date1
    let date2
    if (inputs.dados1.data_ato) {
      date1 = new Date(Number(inputs.dados1.data_ato.substr(0, 4)), Number(inputs.dados1.data_ato.substr(5, 2) - 1), Number(inputs.dados1.data_ato.substr(8, 2)))
    }
    if (inputs.dados2.data_ato) {
      date2 = new Date(Number(inputs.dados2.data_ato.substr(0, 4)), Number(inputs.dados2.data_ato.substr(5, 2) - 1), Number(inputs.dados2.data_ato.substr(8, 2)))
    }
    return {
      canal: 2,
      dados: [
        {
          data_ato: date1,
          nome_partes: inputs.dados1.nome_partes,
          tipo_ato: inputs.dados1.tipo_ato,
          livro: inputs.dados1.livro_ato,
          folha: inputs.dados1.folha_ato,
        },
        {
          data_ato: date2,
          nome_partes: inputs.dados2.nome_partes,
          tipo_ato: inputs.dados2.tipo_ato,
          livro: inputs.dados2.livro_ato,
          folha: inputs.dados2.folha_ato,
        }
      ],
      forma_entrega: forma_entrega,
      endereco: {
        cep: inputs.cep,
        logradouro: inputs.logradouro,
        numero: inputs.numero,
        complemento: inputs.complemento,
        bairro: inputs.bairro,
        cidade: inputs.cidade,
        uf: inputs.uf
      },
      dados_solicitante: {
        nome: inputs.nome.trim(),
        cpf_cnpj: inputs.cpf_cnpj.replace(/\D/g, ''),
        email: inputs.email,
        telefone: inputs.telefone.replace(/\D/g, '')
      },
      mensagem: inputs.mensagem,
      valor_solicitacao: Number(valorServico.toFixed(2)),
      valor_frete: Number(frete.toFixed(2))
    }
  }

  dados3WA(inputs, forma_entrega, valorServico, frete) {
    let date1
    let date2
    let date3
    if (inputs.dados1.data_ato) {
      date1 = new Date(Number(inputs.dados1.data_ato.substr(0, 4)), Number(inputs.dados1.data_ato.substr(5, 2) - 1), Number(inputs.dados1.data_ato.substr(8, 2)))
    }
    if (inputs.dados2.data_ato) {
      date2 = new Date(Number(inputs.dados2.data_ato.substr(0, 4)), Number(inputs.dados2.data_ato.substr(5, 2) - 1), Number(inputs.dados2.data_ato.substr(8, 2)))
    }
    if (inputs.dados3.data_ato) {
      date3 = new Date(Number(inputs.dados3.data_ato.substr(0, 4)), Number(inputs.dados3.data_ato.substr(5, 2) - 1), Number(inputs.dados3.data_ato.substr(8, 2)))
    }
    return {
      canal: 2,
      dados: [
        {
          data_ato: date1 || null,
          nome_partes: inputs.dados1.nome_partes,
          tipo_ato: inputs.dados1.tipo_ato,
          livro: inputs.dados1.livro_ato,
          folha: inputs.dados1.folha_ato,
        },
        {
          data_ato: date2 || null,
          nome_partes: inputs.dados2.nome_partes,
          tipo_ato: inputs.dados2.tipo_ato,
          livro: inputs.dados2.livro_ato,
          folha: inputs.dados2.folha_ato,
        },
        {
          data_ato: date3 || null,
          nome_partes: inputs.dados3.nome_partes,
          tipo_ato: inputs.dados3.tipo_ato,
          livro: inputs.dados3.livro_ato,
          folha: inputs.dados3.folha_ato,
        }
      ],
      forma_entrega: forma_entrega,
      endereco: {
        cep: inputs.cep,
        logradouro: inputs.logradouro,
        numero: inputs.numero,
        complemento: inputs.complemento,
        bairro: inputs.bairro,
        cidade: inputs.cidade,
        uf: inputs.uf
      },
      dados_solicitante: {
        nome: inputs.nome.trim(),
        cpf_cnpj: inputs.cpf_cnpj.replace(/\D/g, ''),
        email: inputs.email,
        telefone: inputs.telefone.replace(/\D/g, '')
      },
      mensagem: inputs.mensagem,
      valor_solicitacao: Number(valorServico.toFixed(2)),
      valor_frete: Number(frete.toFixed(2))
    }

  }

  dados3WOA(inputs, forma_entrega, valorServico, frete) {
    let date1
    let date2
    let date3
    if (inputs.dados1.data_ato) {
      date1 = new Date(Number(inputs.dados1.data_ato.substr(0, 4)), Number(inputs.dados1.data_ato.substr(5, 2) - 1), Number(inputs.dados1.data_ato.substr(8, 2)))
    }
    if (inputs.dados2.data_ato) {
      date2 = new Date(Number(inputs.dados2.data_ato.substr(0, 4)), Number(inputs.dados2.data_ato.substr(5, 2) - 1), Number(inputs.dados2.data_ato.substr(8, 2)))
    }
    if (inputs.dados3.data_ato) {
      date3 = new Date(Number(inputs.dados3.data_ato.substr(0, 4)), Number(inputs.dados3.data_ato.substr(5, 2) - 1), Number(inputs.dados3.data_ato.substr(8, 2)))
    }
    return {
      canal: 2,
      dados: [
        {
          data_ato: date1 || null,
          nome_partes: inputs.dados1.nome_partes,
          tipo_ato: inputs.dados1.tipo_ato,
          livro: inputs.dados1.livro_ato,
          folha: inputs.dados1.folha_ato,
        },
        {
          data_ato: date2 || null,
          nome_partes: inputs.dados2.nome_partes,
          tipo_ato: inputs.dados2.tipo_ato,
          livro: inputs.dados2.livro_ato,
          folha: inputs.dados2.folha_ato,
        },
        {
          data_ato: date3 || null,
          nome_partes: inputs.dados3.nome_partes,
          tipo_ato: inputs.dados3.tipo_ato,
          livro: inputs.dados3.livro_ato,
          folha: inputs.dados3.folha_ato,
        }
      ],
      forma_entrega: forma_entrega,
      endereco: {
        cep: inputs.cep,
        logradouro: inputs.logradouro,
        numero: inputs.numero,
        complemento: inputs.complemento,
        bairro: inputs.bairro,
        cidade: inputs.cidade,
        uf: inputs.uf
      },
      dados_solicitante: {
        nome: inputs.nome.trim(),
        cpf_cnpj: inputs.cpf_cnpj.replace(/\D/g, ''),
        email: inputs.email,
        telefone: inputs.telefone.replace(/\D/g, '')
      },
      mensagem: inputs.mensagem,
      valor_solicitacao: Number(valorServico.toFixed(2)),
      valor_frete: Number(frete.toFixed(2))
    }
  }

  dados4WA(inputs, forma_entrega, valorServico, frete) {
    let date1
    let date2
    let date3
    let date4
    if (inputs.dados1.data_ato) {
      date1 = new Date(Number(inputs.dados1.data_ato.substr(0, 4)), Number(inputs.dados1.data_ato.substr(5, 2) - 1), Number(inputs.dados1.data_ato.substr(8, 2)))
    }
    if (inputs.dados2.data_ato) {
      date2 = new Date(Number(inputs.dados2.data_ato.substr(0, 4)), Number(inputs.dados2.data_ato.substr(5, 2) - 1), Number(inputs.dados2.data_ato.substr(8, 2)))
    }
    if (inputs.dados3.data_ato) {
      date3 = new Date(Number(inputs.dados3.data_ato.substr(0, 4)), Number(inputs.dados3.data_ato.substr(5, 2) - 1), Number(inputs.dados3.data_ato.substr(8, 2)))
    }
    if (inputs.dados4.data_ato) {
      date4 = new Date(Number(inputs.dados4.data_ato.substr(0, 4)), Number(inputs.dados4.data_ato.substr(5, 2) - 1), Number(inputs.dados4.data_ato.substr(8, 2)))
    }
    return {
      canal: 2,
      dados: [
        {
          data_ato: date1 || null,
          nome_partes: inputs.dados1.nome_partes,
          tipo_ato: inputs.dados1.tipo_ato,
          livro: inputs.dados1.livro_ato,
          folha: inputs.dados1.folha_ato,
        },
        {
          data_ato: date2 || null,
          nome_partes: inputs.dados2.nome_partes,
          tipo_ato: inputs.dados2.tipo_ato,
          livro: inputs.dados2.livro_ato,
          folha: inputs.dados2.folha_ato,
        },
        {
          data_ato: date3 || null,
          nome_partes: inputs.dados3.nome_partes,
          tipo_ato: inputs.dados3.tipo_ato,
          livro: inputs.dados3.livro_ato,
          folha: inputs.dados3.folha_ato,
        },
        {
          data_ato: date4 || null,
          nome_partes: inputs.dados4.nome_partes,
          tipo_ato: inputs.dados4.tipo_ato,
          livro: inputs.dados4.livro_ato,
          folha: inputs.dados4.folha_ato,
        }
      ],
      forma_entrega: forma_entrega,
      endereco: {
        cep: inputs.cep,
        logradouro: inputs.logradouro,
        numero: inputs.numero,
        complemento: inputs.complemento,
        bairro: inputs.bairro,
        cidade: inputs.cidade,
        uf: inputs.uf
      },
      dados_solicitante: {
        nome: inputs.nome.trim(),
        cpf_cnpj: inputs.cpf_cnpj.replace(/\D/g, ''),
        email: inputs.email,
        telefone: inputs.telefone.replace(/\D/g, '')
      },
      mensagem: inputs.mensagem,
      valor_solicitacao: Number(valorServico.toFixed(2)),
      valor_frete: Number(frete.toFixed(2))
    }

  }

  dados4WOA(inputs, forma_entrega, valorServico, frete) {
    let date1
    let date2
    let date3
    let date4
    if (inputs.dados1.data_ato) {
      date1 = new Date(Number(inputs.dados1.data_ato.substr(0, 4)), Number(inputs.dados1.data_ato.substr(5, 2) - 1), Number(inputs.dados1.data_ato.substr(8, 2)))
    }
    if (inputs.dados2.data_ato) {
      date2 = new Date(Number(inputs.dados2.data_ato.substr(0, 4)), Number(inputs.dados2.data_ato.substr(5, 2) - 1), Number(inputs.dados2.data_ato.substr(8, 2)))
    }
    if (inputs.dados3.data_ato) {
      date3 = new Date(Number(inputs.dados3.data_ato.substr(0, 4)), Number(inputs.dados3.data_ato.substr(5, 2) - 1), Number(inputs.dados3.data_ato.substr(8, 2)))
    }
    if (inputs.dados4.data_ato) {
      date4 = new Date(Number(inputs.dados4.data_ato.substr(0, 4)), Number(inputs.dados4.data_ato.substr(5, 2) - 1), Number(inputs.dados4.data_ato.substr(8, 2)))
    }
    return {
      canal: 2,
      dados: [
        {
          data_ato: date1 || null,
          nome_partes: inputs.dados1.nome_partes,
          tipo_ato: inputs.dados1.tipo_ato,
          livro: inputs.dados1.livro_ato,
          folha: inputs.dados1.folha_ato,
        },
        {
          data_ato: date2 || null,
          nome_partes: inputs.dados2.nome_partes,
          tipo_ato: inputs.dados2.tipo_ato,
          livro: inputs.dados2.livro_ato,
          folha: inputs.dados2.folha_ato,
        },
        {
          data_ato: date3 || null,
          nome_partes: inputs.dados3.nome_partes,
          tipo_ato: inputs.dados3.tipo_ato,
          livro: inputs.dados3.livro_ato,
          folha: inputs.dados3.folha_ato,
        },
        {
          data_ato: date4 || null,
          nome_partes: inputs.dados4.nome_partes,
          tipo_ato: inputs.dados4.tipo_ato,
          livro: inputs.dados4.livro_ato,
          folha: inputs.dados4.folha_ato,
        }
      ],
      forma_entrega: forma_entrega,
      endereco: {
        cep: inputs.cep,
        logradouro: inputs.logradouro,
        numero: inputs.numero,
        complemento: inputs.complemento,
        bairro: inputs.bairro,
        cidade: inputs.cidade,
        uf: inputs.uf
      },
      dados_solicitante: {
        nome: inputs.nome.trim(),
        cpf_cnpj: inputs.cpf_cnpj.replace(/\D/g, ''),
        email: inputs.email,
        telefone: inputs.telefone.replace(/\D/g, '')
      },
      mensagem: inputs.mensagem,
      valor_solicitacao: Number(valorServico.toFixed(2)),
      valor_frete: Number(frete.toFixed(2))
    }
  }

  dados5WA(inputs, forma_entrega, valorServico, frete) {
    let date1
    let date2
    let date3
    let date4
    let date5
    if (inputs.dados1.data_ato) {
      date1 = new Date(Number(inputs.dados1.data_ato.substr(0, 4)), Number(inputs.dados1.data_ato.substr(5, 2) - 1), Number(inputs.dados1.data_ato.substr(8, 2)))
    }
    if (inputs.dados2.data_ato) {
      date2 = new Date(Number(inputs.dados2.data_ato.substr(0, 4)), Number(inputs.dados2.data_ato.substr(5, 2) - 1), Number(inputs.dados2.data_ato.substr(8, 2)))
    }
    if (inputs.dados3.data_ato) {
      date3 = new Date(Number(inputs.dados3.data_ato.substr(0, 4)), Number(inputs.dados3.data_ato.substr(5, 2) - 1), Number(inputs.dados3.data_ato.substr(8, 2)))
    }
    if (inputs.dados4.data_ato) {
      date4 = new Date(Number(inputs.dados4.data_ato.substr(0, 4)), Number(inputs.dados4.data_ato.substr(5, 2) - 1), Number(inputs.dados4.data_ato.substr(8, 2)))
    }
    if (inputs.dados5.data_ato) {
      date5 = new Date(Number(inputs.dados5.data_ato.substr(0, 4)), Number(inputs.dados5.data_ato.substr(5, 2) - 1), Number(inputs.dados5.data_ato.substr(8, 2)))
    }
    return {
      canal: 2,
      dados: [
        {
          data_ato: date1 || null,
          nome_partes: inputs.dados1.nome_partes,
          tipo_ato: inputs.dados1.tipo_ato,
          livro: inputs.dados1.livro_ato,
          folha: inputs.dados1.folha_ato,
        },
        {
          data_ato: date2 || null,
          nome_partes: inputs.dados2.nome_partes,
          tipo_ato: inputs.dados2.tipo_ato,
          livro: inputs.dados2.livro_ato,
          folha: inputs.dados2.folha_ato,
        },
        {
          data_ato: date3 || null,
          nome_partes: inputs.dados3.nome_partes,
          tipo_ato: inputs.dados3.tipo_ato,
          livro: inputs.dados3.livro_ato,
          folha: inputs.dados3.folha_ato,
        },
        {
          data_ato: date4 || null,
          nome_partes: inputs.dados4.nome_partes,
          tipo_ato: inputs.dados4.tipo_ato,
          livro: inputs.dados4.livro_ato,
          folha: inputs.dados4.folha_ato,
        },
        {
          data_ato: date5 || null,
          nome_partes: inputs.dados5.nome_partes,
          tipo_ato: inputs.dados5.tipo_ato,
          livro: inputs.dados5.livro_ato,
          folha: inputs.dados5.folha_ato,
        }
      ],
      forma_entrega: forma_entrega,
      endereco: {
        cep: inputs.cep,
        logradouro: inputs.logradouro,
        numero: inputs.numero,
        complemento: inputs.complemento,
        bairro: inputs.bairro,
        cidade: inputs.cidade,
        uf: inputs.uf
      },
      dados_solicitante: {
        nome: inputs.nome.trim(),
        cpf_cnpj: inputs.cpf_cnpj.replace(/\D/g, ''),
        email: inputs.email,
        telefone: inputs.telefone.replace(/\D/g, '')
      },
      mensagem: inputs.mensagem,
      valor_solicitacao: Number(valorServico.toFixed(2)),
      valor_frete: Number(frete.toFixed(2))
    }

  }

  dados5WOA(inputs, forma_entrega, valorServico, frete) {
    let date1
    let date2
    let date3
    let date4
    let date5
    if (inputs.dados1.data_ato) {
      date1 = new Date(Number(inputs.dados1.data_ato.substr(0, 4)), Number(inputs.dados1.data_ato.substr(5, 2) - 1), Number(inputs.dados1.data_ato.substr(8, 2)))
    }
    if (inputs.dados2.data_ato) {
      date2 = new Date(Number(inputs.dados2.data_ato.substr(0, 4)), Number(inputs.dados2.data_ato.substr(5, 2) - 1), Number(inputs.dados2.data_ato.substr(8, 2)))
    }
    if (inputs.dados3.data_ato) {
      date3 = new Date(Number(inputs.dados3.data_ato.substr(0, 4)), Number(inputs.dados3.data_ato.substr(5, 2) - 1), Number(inputs.dados3.data_ato.substr(8, 2)))
    }
    if (inputs.dados4.data_ato) {
      date4 = new Date(Number(inputs.dados4.data_ato.substr(0, 4)), Number(inputs.dados4.data_ato.substr(5, 2) - 1), Number(inputs.dados4.data_ato.substr(8, 2)))
    }
    if (inputs.dados5.data_ato) {
      date5 = new Date(Number(inputs.dados5.data_ato.substr(0, 4)), Number(inputs.dados5.data_ato.substr(5, 2) - 1), Number(inputs.dados5.data_ato.substr(8, 2)))
    }
    return {
      canal: 2,
      dados: [
        {
          data_ato: date1 || null,
          nome_partes: inputs.dados1.nome_partes,
          tipo_ato: inputs.dados1.tipo_ato,
          livro: inputs.dados1.livro_ato,
          folha: inputs.dados1.folha_ato,
        },
        {
          data_ato: date2 || null,
          nome_partes: inputs.dados2.nome_partes,
          tipo_ato: inputs.dados2.tipo_ato,
          livro: inputs.dados2.livro_ato,
          folha: inputs.dados2.folha_ato,
        },
        {
          data_ato: date3 || null,
          nome_partes: inputs.dados3.nome_partes,
          tipo_ato: inputs.dados3.tipo_ato,
          livro: inputs.dados3.livro_ato,
          folha: inputs.dados3.folha_ato,
        },
        {
          data_ato: date4 || null,
          nome_partes: inputs.dados4.nome_partes,
          tipo_ato: inputs.dados4.tipo_ato,
          livro: inputs.dados4.livro_ato,
          folha: inputs.dados4.folha_ato,
        },
        {
          data_ato: date5 || null,
          nome_partes: inputs.dados5.nome_partes,
          tipo_ato: inputs.dados5.tipo_ato,
          livro: inputs.dados5.livro_ato,
          folha: inputs.dados5.folha_ato,
        }
      ],
      forma_entrega: forma_entrega,
      endereco: {
        cep: inputs.cep,
        logradouro: inputs.logradouro,
        numero: inputs.numero,
        complemento: inputs.complemento,
        bairro: inputs.bairro,
        cidade: inputs.cidade,
        uf: inputs.uf
      },
      dados_solicitante: {
        nome: inputs.nome.trim(),
        cpf_cnpj: inputs.cpf_cnpj.replace(/\D/g, ''),
        email: inputs.email,
        telefone: inputs.telefone.replace(/\D/g, '')
      },
      mensagem: inputs.mensagem,
      valor_solicitacao: Number(valorServico.toFixed(2)),
      valor_frete: Number(frete.toFixed(2))
    }
  }


}
