import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpHeaders } from '@angular/common/http';
import { LoadingService } from './loading.service';
import { Router } from '@angular/router';
import { BuscaCepService } from './busca-cep.service';
import { ProvidenciasService } from './providencias.service';
import { MultiSolicitationService } from './multi-solicitation.service'

interface arrayConsulta {
  codigo: number;
  data_criacao: Date;
  codigo_simples: number;
  resumo: [
    {
      descricao: string;
      id: number;
      qtd: number;
    }
  ],
  solicitacoes: [
    {
      data_ato: string;
      codigo: number;
      creation_date: string;
      folha_ato: string;
      forma_entrega: number;
      livro_ato: string;
      mensagem: string;
      nome_partes: string;
      entrega: number;
      solicitante: {
        email: string;
        nome: string;
        cpf_cnpj: string;
        telefone: string;
      },
      status: string;
      tipo_ato: string;
      valor_frete: number;
      valor_solicitacao: number;
    }
  ],
  tipo_retirada: string;

}

interface tokenInterface {
  access_token: string;
  expires_in: number;
  token_type: string;
}

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  buscarSolicitacao = false
  temEndereco = false
  access_token
  clicked = false
  opcaoEntregaSelecionada: string = 'Retirar no cartório';
  forma_entrega
  opcoesEntrega: string[] = ['Retirar no cartório', 'Entregar no endereço'];
  arraySolicitacoes: arrayConsulta
  codigo_solicitacao: number = null
  cpfSolicitacao: string = null
  base64 = []
  summaryCheck: arrayConsulta
  error = false

  constructor(private apiService: ApiService,
    public loadingService: LoadingService,
    public router: Router,
    public buscaCepService: BuscaCepService,
    public providenciasService: ProvidenciasService,
    public multiSolicitationService: MultiSolicitationService) { }

  gerarPedido(inputs) {
    this.clicked = true
    let dataWithAddress: Object
    let dataWithoutAddress: Object
    switch (this.buscaCepService.qtdSolicitacoes) {
      case 1:
        dataWithAddress = this.multiSolicitationService.dados1WA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        dataWithoutAddress = this.multiSolicitationService.dados1WOA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        break;
      case 2:
        dataWithAddress = this.multiSolicitationService.dados2WA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        dataWithoutAddress = this.multiSolicitationService.dados2WOA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        break;
      case 3:
        dataWithAddress = this.multiSolicitationService.dados3WA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        dataWithoutAddress = this.multiSolicitationService.dados3WOA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        break;
      case 4:
        dataWithAddress = this.multiSolicitationService.dados4WA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        dataWithoutAddress = this.multiSolicitationService.dados4WOA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        break;
      case 5:
        dataWithAddress = this.multiSolicitationService.dados5WA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        dataWithoutAddress = this.multiSolicitationService.dados5WOA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        break;
    }
    debugger
    this.apiService.setHeader(this.access_token)
    if (this.forma_entrega === 2) {
      this.apiService.postApi<any>('/solicitacoes', dataWithAddress).subscribe(result => {
        this.uploadArquivo(result.solicitacao.codigo)
        this.router.navigate(['/finish/' + result.solicitacao.codigo])
        this.clicked = false
      }, error => {
        this.loadingService.isActive = false
        this.clicked = false
      })
    } else {
      this.apiService.postApi<any>('/solicitacoes', dataWithoutAddress).subscribe(result => {
        this.uploadArquivo(result.solicitacao.codigo)
        this.router.navigate(['/finish/' + result.solicitacao.codigo])
        this.clicked = false
      }, error => {
        this.loadingService.isActive = false
        this.clicked = false
      })
    }
  }

  uploadArquivo(userCode) {
    this.apiService.postFork('/solicitacoes/' + userCode + '/uploads', this.base64).subscribe(res => {
      this.loadingService.isActive = false
      this.router.navigate(['/finish/' + userCode])
    })
  }

  consultar(id) {
    this.apiService.setHeader(this.access_token)
    this.apiService.getApi('/solicitacoes/' + id).subscribe((res: arrayConsulta) => {
      this.arraySolicitacoes = res
      this.buscarSolicitacao = true
      this.temEndereco = true
      this.loadingService.isActive = false
    }, error => {
      this.loadingService.isActive = false
      this.temEndereco = false
      this.buscarSolicitacao = false
    })
  }

  getUrl(url) {
    let index = url.lastIndexOf('/')
    let fileName = url.slice(index + 1)
    return fileName
  }

  consultarSimple(id, cpf) {
    this.base64 = []
    this.loadingService.isActive = true
    this.apiService.setHeaderBusca(this.access_token, cpf)
    this.apiService.getApi('/solicitacoes/simples/' + id).subscribe((res2: arrayConsulta) => {
      this.providenciasService.getProvidencias(res2)
      this.arraySolicitacoes = res2
      this.providenciasService.temProvidencia = res2
      this.buscarSolicitacao = true
      this.temEndereco = true
      this.error = false
    }, err => {
      this.error = true
      this.loadingService.isActive = false
    })
  }
}