import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { LoadingService } from './loading.service';
import { MatSnackBar } from '@angular/material/snack-bar';

interface resCalcCep {
  Servicos: {
    cServico: [
      {
        Codigo: [
          string
        ],
        Valor: [
          number
        ],
        PrazoEntrega: [
          number
        ],
        ValorSemAdicionais: [
          string
        ],
        ValorMaoPropria: [
          string
        ],
        ValorAvisoRecebimento: [
          string
        ],
        ValorValorDeclarado: [
          string
        ],
        EntregaDomiciliar: [
          string
        ],
        EntregaSabado: [
          string
        ],
        obsFim: [
          string
        ],
        Erro: [
          string
        ],
        MsgErro: [
          string
        ]
      }
    ]
  }
}

@Injectable({
  providedIn: 'root'
})
export class BuscaCepService {

  url = 'https://viacep.com.br/ws/'
  mostraValores = false
  qtdSolicitacoes = 1
  valor = 0
  valorServico = 70.80
  valorTotal = this.valorServico
  frete = 0
  summary
  attempts = 0
  freightCalculation = true
  freightCalculationFailedMessage = `Não foi possível calcular o valor do frete. 
  Você receberá esse valor por e-mail após análise do cartório.`


  constructor(private apiService: ApiService,
    public loadingService: LoadingService,
    private http: HttpClient,
    private _snackBar: MatSnackBar) {
  }

  getCep(params) {
    this.apiService.getCepApi(this.url + params + '/json').subscribe(res => {
    })
  }

  calcularFrete(cepDestino) {
    this.loadingService.isActive = true
    this.apiService.getApiCep('calculo-frete?cepOrigem=' +
      this.apiService.chosenCartorio.cep +
      '&cepDestino=' +
      cepDestino).subscribe((res: resCalcCep) => {
        this.mostraValores = true
        this.loadingService.isActive = false
        this.freightCalculation = true
        this.valor = res.Servicos.cServico[0].Valor[0]
        this.calculaTotal(this.valor)
      }, err => {
        if (this.attempts < 2) {
          this.calcularFrete(cepDestino)
          this.attempts++
        } else {
          this.attempts = 0
          this.loadingService.isActive = false
          this.freightCalculation = false
          this.openSnackBar(this.freightCalculationFailedMessage, 'Fechar')
        }
      })
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

  calculaTotal(valorFrete) {
    let servico = this.valorServico
    this.frete = parseFloat(valorFrete.replace(',', '.'))
    this.frete.toFixed(2)
    this.valorTotal = servico += this.frete
  }
  confirmTotal() {
    if (this.summary.entrega === 1) {
      this.frete = 0
      this.valorTotal = this.valorServico
    }
  }

}
