import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from './token.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  token = null
  favIcon: HTMLLinkElement = document.querySelector('#appIcon')
  title: Element = document.querySelector('#title')
  cpfPorPagador = ''

  chosenCartorio = {
    favIcon: this.favIcon,
    title: this.title,
    userId: '',
    cep: ''
  }

  cartorios = [
    {
      id: '390228c0-eef0-11ea-924e-0af504ceb319',
      url: 'https://solicitacoes.cartoriotoledo.com.br/consulta',
      nome: 'Cartório Toledo',
      logo: 'https://inova-cartorios-solicitacoes.s3-us-west-2.amazonaws.com/email_imgs/toledo/logo_toledo.png',
      cep: '05401450'
    },
    {
      id: '81147fbf-14a0-11eb-8252-0a96c6f995dd',
      url: 'https://solicitacoes.2cartorio.com.br/consulta',
      nome: 'Cartório Tabelião 2',
      logo: 'https://inova-cartorios-solicitacoes.s3-us-west-2.amazonaws.com/email_imgs/2cartorio/logo_2cartorio.png',
      cep: '01220010'
    },
    {
      id: 'f2f01938-37f1-11eb-a93a-0e29607613cd',
      url: 'https://solicitacoes.quintocartorio.com.br/consulta',
      nome: 'Quinto Cartório',
      logo: 'https://inova-cartorios-solicitacoes.s3-us-west-2.amazonaws.com/email_imgs/5TBM/logo.png',
      cep: '04715005'
    },
    {
      id: '94dcbdf5-1977-11eb-8252-0a96c6f995dd',
      url: 'https://localhost:4200',
      nome: 'Teste LocalHost',
      logo: 'https://www.google.com/favicon.ico',
      cep: '01220010'
    }
  ]

  /* userId = ' 3901e05b-eef0-11ea-924e-0af504ceb319' */  //teste caio

  private httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': ''
      }
    ),
  }

  public httpOptions2 = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': '',
      }
    ),
  }

  getCartorio(url) {
    
    let removeConsulta = url.slice(8, (url.indexOf('/consulta')))
    let indexToFind = removeConsulta.replaceAll('/', '')
    this.cartorios.forEach(element => {
      if (element.url.indexOf(indexToFind) > 0) {
        this.chosenCartorio.userId = element.id
        this.favIcon.href = element.logo
        this.title.innerHTML = element.nome
        this.chosenCartorio.cep = element.cep
      }
    })
  }

  postFork(params, data) {
    const chamada = data.map(res => {
      return this.http.post(environment.url + params, res, this.httpOptions)
    })
    return forkJoin(chamada)
  }

  constructor(private http: HttpClient,
    public tokenService: TokenService) {
  }

  getCepApi<T>(params): Observable<T> {
    return this.http.get<T>(params)
  }

  getApi<T>(params): Observable<T> {
    return this.http.get<T>(environment.url + params, this.httpOptions)
  }

  async getApiProvidencias<T>(params) {

    await this.tokenService.validarToken()
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.tokenService.token.access_token,
        'Content-Type': 'application/json',
        'cartorio': this.chosenCartorio.userId,
      }),
    };
    return this.http.get<T>(environment.url + params, httpOptions).toPromise()
  }

  getApiCep<T>(params): Observable<T> {
    return this.http.get<T>(environment.url + params, this.httpOptions)
  }

  postApi<T>(params, body): Observable<T> {
    return this.http.post<T>(environment.url + params, body, this.httpOptions)
  }


  putApi(url, body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.token,
        'Content-Type': 'application/json',
        'cartorio': this.chosenCartorio.userId
      }),
    };
    return this.http.put<string>(environment.url + url, body, httpOptions).toPromise()
  }

  setHeader(token) {
    this.httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token,
          'cartorio': this.chosenCartorio.userId
        }
      ),
    }
  }

  setHeaderBusca(token, cpf) {
    this.httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token,
          'cartorio': this.chosenCartorio.userId,
          'identificacao': cpf
        }
      ),
    }
  }


}



