<div class="content">
    <h1 class="title-section">
        CONFIRMAR INFORMAÇÕES PREENCHIDAS
    </h1>
    <div class="dados-ato" *ngIf="buscaCepService.qtdSolicitacoes === 1">
        <h3>Nome das partes: <i>{{infoService?.summaryCheck?.dados1.nome_partes || 'Não informado'}}</i></h3>
        <h3>Tipo do ato: <i>{{infoService?.summaryCheck?.dados1.tipo_ato || 'Não informado'}}</i></h3>
        <h3>Livro: <i>{{infoService?.summaryCheck?.dados1.livro_ato || 'Não informado'}}</i></h3>
        <h3>Folha: <i>{{infoService?.summaryCheck?.dados1.folha_ato || 'Não informado'}}</i> </h3>
    </div>
    <div class="dados-ato" *ngIf="buscaCepService.qtdSolicitacoes > 1">
        <h3>Quantidade de solicitações: <i>{{buscaCepService?.qtdSolicitacoes}}</i></h3>
    </div>

    <h3>Forma de retirada: <i>{{infoService?.summaryCheck?.entrega === 1 ? 'Retirar no cartório' : 'Entregar no
            endereço'}}</i></h3>

    <div *ngIf="infoService?.summaryCheck?.entrega === 2" class="forma-entrega">
        <h3>Endereço de entrega:
            <i>{{infoService?.summaryCheck?.logradouro}} -
                {{infoService?.summaryCheck?.numero}} -
                {{infoService?.summaryCheck?.cep}} -
                {{infoService?.summaryCheck?.cidade}} -
                {{infoService?.summaryCheck?.uf}}
            </i>
        </h3>
    </div>
    <div class="dados-solicitante">
        <h3 *ngIf="infoService?.summaryCheck?.nome">Nome do solicitante: <i>{{infoService?.summaryCheck?.nome}}</i></h3>
        <h3 *ngIf="infoService?.summaryCheck?.cpf_cnpj">CPF/CNPJ do solicitante:
            <i>{{infoService?.summaryCheck?.cpf_cnpj}}</i>
        </h3>
        <h3 *ngIf="infoService?.summaryCheck?.email">Email: <i>{{infoService?.summaryCheck?.email}}</i></h3>
        <h3 *ngIf="infoService?.summaryCheck?.telefone">Telefone: <i>{{infoService?.summaryCheck?.telefone}}</i></h3>
        <h3 *ngIf="infoService?.summaryCheck?.mensagem">Mensagem: <i>{{infoService?.summaryCheck?.mensagem}}</i></h3>
    </div>
    <div class="valores">
        <h3>Valor do serviço: <i>{{buscaCepService?.valorServico | currency :'BRL':true}}</i></h3>
        <h3>Valor do frete:
            <i>
                {{ buscaCepService.freightCalculation ? (showFreightValue() | currency: 'BRL': true) :
                buscaCepService.freightCalculationFailedMessage}}
            </i>
        </h3>
        <h2 style="text-align: center;">Valor total do serviço: {{buscaCepService?.valorTotal | currency:'BRL':true}}
        </h2>

    </div>

    <div class="action">
        <button mat-raised-button color="primary" class="btn" (click)="onNoClick()">Cancelar</button>
        <button mat-raised-button color="primary" [disabled]="this.infoService.clicked" class="btn"
            (click)="submit()">Confirmar</button>
    </div>
</div>