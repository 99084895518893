import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { LoadingService } from './loading.service';
import { TokenService } from './token.service';

interface tokenInterface {
  access_token: string;
  expires_in: number;
  token_type: string;
}
export interface PendenciaInterface {
  forEach(arg0: (element: any) => void);
  codigo: string;
  data_pendencia: Date;
  texto_pendencia: string;
  resolvido: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProvidenciasService {

  public resProvidencia: PendenciaInterface = null
  public temProvidencia = null
  public blockTableView = false
  public atualizarTela = new BehaviorSubject<boolean>(false)
  public providenciaSelecionada = null
  public allPend: Array<any> = []
  public hideBadge: boolean = false


  constructor(private apiService: ApiService,
    private loadingService: LoadingService,
    private tokenService: TokenService) { }

  providencia(msg) {
    let alt = {
      texto_pendencia: msg,
      resolvido: true
    }
    this.apiService.putApi('/solicitacoes/' +
      this.temProvidencia.codigo +
      '/pendencias/' +
      this.providenciaSelecionada.codigo, alt).then(res => {
        this.atualizarTela.next(true)
      }, err => {
        this.loadingService.isActive = false
      })
  }

  async getProvidencias(info) {
    this.hideBadge = true
    this.allPend = []
    this.blockTableView = false
    await this.tokenService.getToken().subscribe((result: tokenInterface) => {
      this.apiService.token = result.access_token
      this.apiService.setHeader(result.access_token)
    })
    this.apiService.getApiProvidencias('/solicitacoes/' + info.codigo + '/pendencias').then((res: PendenciaInterface) => {
      this.resProvidencia = res
      console.log(res);
      if (!res[0]?.codigo) {
        this.blockTableView = true
      } else {
        this.blockTableView = false
      }
      res.forEach((element) => {
        if (element.resolvido === 0) {
          this.allPend.push(element.codigo)
        }
      });
      if (this.allPend.length !== 0) {
        this.hideBadge = false
      }
      this.loadingService.isActive = false
    })
  }


}
